@import "https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css";
/* Content Header */
.content-header {
  background-color: #034681;
  width: 100%;
  padding: 10px 30px 10px 90px;
  font-family: sans-serif;
  font-size: 32px;
  color: #f5f5f5;
  text-align: left;
  display: block;
  position: absolute;
  z-index: 1000;
  top: 100px;
  height: 68px;
}

/* Grid - Cells */
.cell {
  font-size: 14px;
  color: #212529;
  font-family: sans-serif;
  display: table-cell;
  white-space: nowrap;
  line-height: 40px;
  text-transform: capitalize;
  padding-left: 10px;
}

.cell:last-child {
  padding-right: 10px;
}

.money {
  text-align: right;
}

.number {
  text-align: right;
}

.negative {
  /* color: red; */
  color: inherit;
}

.money.negative::before {
  content: "(";
}

.money.negative::after {
  content: ")";
}

.alert {
  color: red;
}

/* .Collapsible {
  background-color: white;
} */

.Collapsible__contentInner {
  padding: 10px;
  /* border: 1px solid #ebebeb; */
  border-top: 0;
}

.Collapsible__contentInner p {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
}

.Collapsible__contentInner p:last-child {
  margin-bottom: 0;
}

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  color: #333333;
  position: relative;
  /* border: 1px solid white; */
  padding: 10px;
  padding-left: 25px;
  /* background: #00ac9d; */
  color: #034681;
}
.Collapsible__trigger:after {
  content: "\f107";
  font-family: "FontAwesome";
  font-weight: 900; /* Fix version 5.0.9 */
  position: absolute;
  /* right: 10px; */
  left: 10px;
  top: 10px;
  display: block;
  transition: transform 300ms;
}

.Collapsible__trigger.is-open:after {
  transform: rotateZ(180deg);
}

.Collapsible__trigger.is-disabled {
  opacity: 0.5;
  background-color: grey;
}

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;
}

.CustomTriggerCSS--open {
  background-color: darkslateblue;
}

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #cbb700;
  color: black;
}

/* Grid DataTable */

.data-table-wrapper {
  /* overflow-x: auto; */
  /* margin: 10px; */
  width: 99%;
  /* overflow-y: scroll;
  height: 400px; */
}

.data-table {
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;
  display: table;
  overflow-x: auto;
}


.data-table-head {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}
.data-table-body {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

.Collapsible__contentOuter {
  width: 90vw;
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
  overflow-x: auto;
  display:table-caption;
}

  .Collapsible__contentOuter .data-table {
    display: inherit;
    width: 100%;
  }
/* Grid - HeaderCell */

.sort-icon {
  margin-left: 5px;
  font-family: "FontAwesome";
  font-size: 12.8px;
  color: #015cab;
  line-height: 41px;
}

.header-cell {
  height: 41px;
  white-space: nowrap;
  padding-left: 10px;
  padding-right: 3%;
  display: table-cell;
  vertical-align: inherit;
  font-size: 12.8px;
  color: #015cab;
  font-family: sans-serif;
  border-top: 1px solid #015cab;
  border-bottom: 1px solid #015cab;
  font-weight: 500;
  background-color: #ebebeb;
}

.header-cell:last-child{
  padding-right:10px;
}

.money {
  text-align: right;
}

.number {
  text-align: right;
}
.header-row .money, .header-row .number{
  text-align:left;
}

/* Grid - Header Row */

.header-row {
  display: table-row;
}

/* Grid - Inline Filter Cell */

.inline-filter-cell {
  background-color: white;
  display: table-cell;
  border-bottom: 1px solid #bbbbbb;
  height: 30px;
  white-space: nowrap;
  padding-left: 10px;
  color: #015cab;
}

.inline-filter-cell:last-child{
  padding-right:10px;
}

.inline-filter-row .filter-text-input {
  display: inline-block;
  border: 0;
  padding-left: 5px;
}

.filter-text-input:focus {
  box-shadow: none;
}

.filter-icon {
  font-size: 12.8px;
  line-height: 31px;
}

.selected-filter-type {
  background-color: #e7eef8;
}

/* .filter-text-input {
  height: 30px;
} */

.inline-filter-cell .filter-text-input {
  background-image: none;
  height: 30px;
}

/* Grid - Inline Filter Row */

.inline-filter-row {
  display: table-row;
}

/* Grid - Paging */

.data-table-paging {
  display: inline;
}

.page-selector {
  width: auto;
  display: inline-block;
}

.paginationControlButton {
  font-size: 16px;
  font-family: "FontAwesome";
  color: #015cab;
  font-style: normal;
}

.paginationFooter {
  font-size: 16px;
}

/* Grid - Row */

.data-table-row {
  border-bottom: solid 1px #bbbbbb;
    display: table-row;
    vertical-align: middle;
    height: 40px;
}

/* Grid - Total Footer */

.total-footer {
  display: table-footer-group;
  vertical-align: middle;
  border-color: inherit;
  background-color: #034681;
  color: #ffffff;
}

.grand-total-footer-text {
  font-size: 16px;
  font-family: sans-serif;
}

.money-cell {
  text-align: right;
}

.number-cell {
  text-align: center;
}

.negative {
  /* color: red; */
  color: inherit;
}

.money.negative::before {
  content: "(";
}

.money.negative::after {
  content: ")";
}

.total-footer-row {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
.total-footer-cell {
  font-size: 14px;
  white-space: nowrap;
  z-index: 0;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  display: table-cell;
  vertical-align: inherit;
}

/* Buttons */

.btn-primary {
  height: 40px;
  border-radius: 3px;
  padding: 20px;
  font-size: 14px;
  line-height: 0px;
  border: none;
  background-color: #015cab;
  color: #f5f5f5;
}

.btn-secondary {
  height: 40px;
  border-radius: 3px;
  padding: 20px;
  font-size: 14px;
  line-height: 0px;
  border: 1px solid #015cab;
  background-color: #f5f5f5;
  color: #015cab;
}

.btn-tertiary {
  background-color: #d40f0f;
  border: none;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  color: #f5f5f5;
  display: inline-block;
  font-size: 14px;
  height: 40px;
  border-radius: 3px;
  width: 150px;
  padding: 2px;
}

.action-link {
  color: #015cab;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  border-style: none;
  background-color: #f5f5f5;
}

.action-link:focus {
  border-style: none;
  outline-style: none;
}

.grayed {
  color: #868e96;
}
